import { json } from '@remix-run/node';
import { Outlet, useLoaderData } from '@remix-run/react';
import { getClientIPAddress } from 'remix-utils/get-client-ip-address';

import { useSegmentInit } from '~/hooks/useSegmentInit';
import { authenticator } from '~/services/auth.server';

import type { LoaderFunctionArgs } from '@remix-run/node';
import type { MetaFunction } from '@remix-run/react';

export const meta: MetaFunction = () => [
  {
    tagName: 'link',
    rel: 'canonical',
    href: 'https://app.raffle.ai',
  },
];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  let user = await authenticator.isAuthenticated(request);
  const ip = getClientIPAddress(request);

  return json({ isAuthenticated: Boolean(user), ip });
};

export default function View() {
  const { isAuthenticated, ip } = useLoaderData<typeof loader>();

  useSegmentInit({ shouldLogout: !isAuthenticated, ip });

  return <Outlet />;
}
